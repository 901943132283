import type { IBreadcrumbsData } from '@/types/types'
import { TestABGroups } from '@/types/enums'

import navigateToBundlePage from '@/utils/navigation/navigateToBundlePage'

import { OrderStatus } from './enums'

export const cards: any = {
  Mastercard: 'https://web-cdn.saudetrevo.com.br/icons/mastercard.svg',
  Amex: 'https://web-cdn.saudetrevo.com.br/icons/amex.svg',
  Dinners: 'https://web-cdn.saudetrevo.com.br/icons/dinners.svg',
  Elo: 'https://web-cdn.saudetrevo.com.br/icons/elo.svg',
  Hiper: 'https://web-cdn.saudetrevo.com.br/icons/hiper.svg',
  Visa: 'https://web-cdn.saudetrevo.com.br/icons/hiper.svg',
}

export const humanBodyIllustrations = {
  female: 'https://web-cdn.saudetrevo.com.br/illustrations/female-body.svg',
  male: 'https://web-cdn.saudetrevo.com.br/illustrations/male-body.svg',
}

export const abTests: TestABGroups[] = [
  TestABGroups.NEW_JOURNEY_WITH_SCHEDULE_DATE,
]

export const testWeights = {
  [TestABGroups.NEW_JOURNEY_WITH_SCHEDULE_DATE]: 0.2,
}

export const abTestControl = {
  [TestABGroups.NEW_JOURNEY_WITH_SCHEDULE_DATE]: {
    fullname: TestABGroups.NEW_JOURNEY_WITH_SCHEDULE_DATE,
    name: TestABGroups.NEW_JOURNEY_WITH_SCHEDULE_DATE,
    control: 0,
  },
  [TestABGroups.NEW_JOURNEY_WITH_SCHEDULE_DATE_CONTROL]: {
    fullname: TestABGroups.NEW_JOURNEY_WITH_SCHEDULE_DATE_CONTROL,
    name: TestABGroups.NEW_JOURNEY_WITH_SCHEDULE_DATE,
    control: 1,
  },

  [TestABGroups.IGNORE_AB_TEST]: {
    fullname: TestABGroups.IGNORE_AB_TEST,
    name: TestABGroups.IGNORE_AB_TEST,
    control: 1,
  },

  [TestABGroups.RESET_AB_TEST]: {
    fullname: TestABGroups.RESET_AB_TEST,
    name: TestABGroups.RESET_AB_TEST,
    control: 1,
  },
}

export const testPages = [
  'index',
  'location',
  'location-exames-slug',
  'location-exames',
  'checkout-forma-de-pagamento',
]

export const BREAKPOINTS = {
  'sm': 640,
  'md': 768,
  'lg': 1024,
  'xl': 1280,
  '2xl': 1536,
}

export const SalesOrderStatus = {
  [OrderStatus.Pending]: 'Aguardando Pagamento',
  [OrderStatus.Waiting_For_Payment]: 'Aguardando Pagamento',
  [OrderStatus.Denied]: 'Pagamento Recusado',
  [OrderStatus.Canceled]: 'Procedimento cancelado',
  [OrderStatus.Approved]: 'Aguardando confirmação do laboratório',
  [OrderStatus.Ready]: 'Liberado para realização',
  [OrderStatus.Finished]: 'Exame realizado',
  [OrderStatus.Refunded]: 'Reembolso finalizado',
  [OrderStatus.Expired]: 'Expirado',
  [OrderStatus.Pending_But_Authorized]: 'Liberado para realização',
  [OrderStatus.Waiting_For_Refund]: 'Aguardando reembolso',
  [OrderStatus.Waiting_For_Results]: 'Aguardando resultado de exame(s)',
  [OrderStatus.Schedule_Confirmation]: 'Aguardando confirmação do paciente',
}

export const SalesOrderStatusColors = {
  [OrderStatus.Pending]: 'text-secondary-500',
  [OrderStatus.Denied]: 'text-error-500',
  [OrderStatus.Canceled]: 'text-secondary-300',
  [OrderStatus.Approved]: 'text-secondary-500',
  [OrderStatus.Ready]: 'text-secondary-300',
  [OrderStatus.Finished]: 'text-information-300',
  [OrderStatus.Refunded]: 'text-secondary-300',
  [OrderStatus.Expired]: 'text-secondary-300',
  [OrderStatus.Pending_But_Authorized]: 'text-secondary-300',
  [OrderStatus.Waiting_For_Payment]: 'text-secondary-500',
  [OrderStatus.Waiting_For_Refund]: 'text-secondary-300',
  [OrderStatus.Waiting_For_Results]: 'text-information-300',
  [OrderStatus.Schedule_Confirmation]: 'text-secondary-500',
}

export const brazilianStates = [
  { name: 'Acre', value: 'AC' },
  { name: 'Alagoas', value: 'AL' },
  { name: 'Amapá', value: 'AP' },
  { name: 'Amazonas', value: 'AM' },
  { name: 'Bahia', value: 'BA' },
  { name: 'Ceará', value: 'CE' },
  { name: 'Distrito Federal', value: 'DF' },
  { name: 'Espírito Santo', value: 'ES' },
  { name: 'Goiás', value: 'GO' },
  { name: 'Maranhão', value: 'MA' },
  { name: 'Mato Grosso', value: 'MT' },
  { name: 'Mato Grosso do Sul', value: 'MS' },
  { name: 'Minas Gerais', value: 'MG' },
  { name: 'Pará', value: 'PA' },
  { name: 'Paraíba', value: 'PB' },
  { name: 'Paraná', value: 'PR' },
  { name: 'Pernambuco', value: 'PE' },
  { name: 'Piauí', value: 'PI' },
  { name: 'Rio de Janeiro', value: 'RJ' },
  { name: 'Rio Grande do Norte', value: 'RN' },
  { name: 'Rio Grande do Sul', value: 'RS' },
  { name: 'Rondônia', value: 'RO' },
  { name: 'Roraima', value: 'RR' },
  { name: 'Santa Catarina', value: 'SC' },
  { name: 'São Paulo', value: 'SP' },
  { name: 'Sergipe', value: 'SE' },
  { name: 'Tocantins', value: 'TO' },
]

const BreadcrumbsRouteCheckout: IBreadcrumbsData = {
  showTitle: true,

  crumbs: [
    {
      index: 0,
      label: 'Comparação de preços',
      redirectTo: navigateToBundlePage,
    },
    {
      index: 1,
      label: 'Agendamento de exames',
      redirectTo: '/agendamento',
    },
    {
      index: 2,
      label: 'Resumo',
      redirectTo: '/checkout',
    },
    {
      index: 3,
      label: 'Checkout',
    },
  ],
}
export const BreadcrumbsRoutes: Record<string, IBreadcrumbsData> = {
  /**
   *  @module none
   *  @title static pages.
   */
  'faq': {
    showTitle: true,
    banner: true,

    crumbs: [
      {
        index: 0,
        label: 'Perguntas frequentes',
      },
    ],
  },
  'quem-somos': {
    showTitle: true,
    banner: true,

    crumbs: [
      {
        index: 0,
        label: 'Quem somos',
      },
    ],
  },
  'politica-de-privacidade': {
    showTitle: true,
    banner: true,

    crumbs: [
      {
        index: 0,
        label: 'Politica de privacidade',
      },
    ],
  },
  'termos-e-condicoes': {
    showTitle: true,
    banner: true,

    crumbs: [
      {
        index: 0,
        label: 'Termos e condições',
      },
    ],
  },
  'compra-garantida': {
    showTitle: true,
    banner: true,

    crumbs: [
      {
        index: 0,
        label: 'Compra garantida',
      },
    ],
  },
  'pagamento-protegido': {
    showTitle: true,
    banner: true,

    crumbs: [
      {
        index: 0,
        label: 'Pagamento protegido',
      },
    ],
  },

  /**
   *  @module profile
   *  @title Perfil routes.
   */
  'perfil': {
    showTitle: true,

    crumbs: [
      {
        index: 0,
        label: 'Área do usuário',
      },
    ],
  },

  'perfil-dados-cadastrais': {
    showTitle: true,

    crumbs: [
      {
        index: 0,
        redirectTo: '/perfil',
        label: 'Área do usuário',
      },
      {
        index: 1,
        label: 'Dados cadastrais',
      },
    ],
  },

  'perfil-meus-pedidos': {
    showTitle: true,

    crumbs: [
      {
        index: 0,
        redirectTo: '/perfil',
        label: 'Área do usuário',
      },
      {
        index: 1,
        label: 'Histórico de exames',
      },
    ],
  },

  'perfil-meus-cupons': {
    showTitle: true,

    crumbs: [
      {
        index: 0,
        label: 'Área do usuário',
        redirectTo: '/perfil',
      },
      {
        index: 1,
        label: 'Cupons',
      },
    ],
  },

  'perfil-meus-pedidos-orderNumber': {
    showTitle: false,

    crumbs: [
      {
        index: 0,
        label: 'Área do usuário',
        redirectTo: '/perfil',
      },
      {
        index: 1,
        label: 'Status do pedido',
      },
    ],
  },

  /**
   *  @module site
   *  @title Site routes
   */
  'location-exames-slug': {
    showTitle: false,

    crumbs: [
      {
        index: 0,
        label: 'Buscar exames',
        redirectTo: () => navigateToWithLocation('/buscar-exames'),
      },
      {
        index: 1,
        label: 'Comparação de preços',
      },
    ],
  },

  /**
   *  @module site
   *  @title Site routes
   */
  'location-exames': {
    showTitle: false,

    crumbs: [
      {
        index: 0,
        label: 'Buscar exames',
        redirectTo: () => navigateToWithLocation('/buscar-exames'),
      },
      {
        index: 1,
        label: 'Comparação de preços',
      },
    ],
  },

  'location-buscar-exames': {
    showTitle: false,

    crumbs: [
      {
        index: 0,
        label: 'Buscar exames',
      },
    ],
  },

  'agendamento': {
    showTitle: true,

    crumbs: [
      {
        index: 0,
        label: 'Comparação de preços',
        redirectTo: navigateToBundlePage,
      },
      {
        index: 1,
        label: 'Agendamento de exames',
      },
    ],
  },

  /**
   *  @module checkout
   *  @title Checkout routes
   */
  'checkout': {
    showTitle: true,

    crumbs: [
      {
        index: 0,
        label: 'Comparação de preços',
        redirectTo: navigateToBundlePage,
      },
      {
        index: 1,
        label: 'Agendamento de exames',
        redirectTo: '/agendamento',
      },
      {
        index: 2,
        label: 'Resumo',
      },
    ],
  },
  'checkout-dados-pessoais': BreadcrumbsRouteCheckout,
  'checkout-endereco-de-cobranca': BreadcrumbsRouteCheckout,
  'checkout-agendamento': BreadcrumbsRouteCheckout,
  'checkout-pedido-medico': BreadcrumbsRouteCheckout,
  'checkout-forma-de-pagamento': BreadcrumbsRouteCheckout,
  'dados-do-plano-de-saude': {
    showTitle: true,

    crumbs: [
      {
        index: 0,
        label: 'Orçamento de exames',
        redirectTo: navigateToBundlePage,
      },
      {
        index: 1,
        label: 'Checkout',
        redirectTo: '/checkout',
      },
      {
        index: 2,
        label: 'Dados do plano de saúde',
      },
    ],
  },
  'solicitacao-de-orcamento': {
    showTitle: false,

    crumbs: [
      {
        index: 0,
        label: 'Solicitação de orçamento',
      },
    ],
  },
}

export const Error: { [key: string]: string } = {
  E17001: 'Cupom não encontrado.',
  E17007: 'Valor mínimo para uso do cupom não atingido.',
  E17005: 'Este cupom não é válido para esta compra.',

  E16011: 'Cupom inválido.',

  E27001: 'No momento, não tem orçamento disponível para realizar pagamento, busque novos exames.',

  E10000: 'Código de verificação inválido, tente novamente.',

  E11016: 'Procedimento não encontrado, tente em outra região.',

  E21030: 'Preencha todos os campos obrigatórios.',
  E21005: 'Orçamento não encontrado.',
  E21007: 'Orçamento sem exames.',
  E21006: 'Orçamento sem endereço.',
  E21009: 'Orçamento desativado.',
  E21014: 'Há procedimentos desativados em seu orçamento.',
  E21011: 'Falha ao aplicar cupom, tente novamente.',
  E21012: 'Usuário sem nome cadastrado.',
  E21008: 'Falha ao finalizar venda, tente novamente.',
  E21021: 'Valor mínimo para uso do cupom não atingido.',

  E13007: 'Pedido não encontrado',
  E13051: 'Pedido não elegível para checkout',
  E13018: 'Número de parcelas maior que o permitido',
  E13030: 'Token do cartão é necessário para pagamento com cartão',
  E13037: 'Erro inesperado no gateway de pagamentos',

  E10003: 'Ocorreu um erro. Por favor, tente novamente.',
  E10025: 'Ocorreu um erro. Por favor, tente novamente.',
  E10026: 'Ocorreu um erro. Por favor, tente novamente.',
  E10027: 'Ocorreu um erro. Por favor, tente novamente.',
  E10004: 'Ocorreu um erro. Por favor, tente novamente.',
  E10009: 'Ocorreu um erro. Por favor, tente novamente.',
  E10010: 'Ocorreu um erro. Por favor, tente novamente.',

  not_found: 'Ocorreu um erro. Por favor, tente novamente.',
  unprocessable_entity: 'Ocorreu um erro. Por favor, tente novamente.',
}

export const CardsContent = [
  {
    icon: 'mdi:flask',
    alt: 'Buscar exames',
    text: 'Quais exames você precisa fazer agora?',
    linkAlt: 'Buscar mais exames',
    link: '/',
  },
  {
    icon: 'mdi:flask-empty-plus',
    alt: 'Histórico de exames',
    text: 'Veja os exames realizados recentemente.',
    linkAlt: 'Visualizar meu histórico',
    link: '/perfil/meus-pedidos',
  },
  {
    icon: 'mdi:account',
    alt: 'Editar dados',
    text: 'Acesse o seu perfil e edite suas informações cadastrais.',
    linkAlt: 'Editar dados',
    link: '/perfil/dados-cadastrais',
  },
  {
    icon: 'mdi:ticket-percent',
    alt: 'Visualizar cupons',
    text: 'Acesse seu perfil e visualize os cupons disponíveis.',
    linkAlt: 'Visualizar cupons',
    link: '/perfil/meus-cupons',
  },
]

export const MetaTags = {
  index: {
    title: 'Saúde Trevo - Compare e Economize em seus Exames!',
    description: 'Compare preços de exames médicos em laboratórios parceiros com a Saúde Trevo. Encontre opções de valor, distância e disponibilidade que cabem no seu bolso, sem mensalidade! Faça seus exames com economia, qualidade e transparência. Mais de 160 parceiros e 20.000 exames realizados. Descubra como é fácil cuidar da sua saúde conosco!',
    image: 'https://web-cdn.saudetrevo.com.br/images/banner_quem_somos.webp',
    canonical: 'https://saudetrevo.com.br',
    robots: 'index, follow',
    twitterCard: 'summary',
    type: 'website',
    articleModifiedTime: '2024-05-05',
  },
  aboutUs: {
    title: 'Quem Somos: Inovação e Acesso à Saúde para Todos | Saúde Trevo',
    description: 'Conheça a história e o propósito da Saúde Trevo: democratizar o acesso à saúde no Brasil. Oferecemos transparência, qualidade e preços acessíveis para exames médicos, ajudando brasileiros sem plano de saúde. Saiba mais sobre nossos fundadores, Ana Elisa e Adam Alves, e nossa missão de levar inovação e cuidado a quem mais precisa.',
    image: 'https://web-cdn.saudetrevo.com.br/images/banner_quem_somos.webp',
    canonical: 'https://saudetrevo.com.br/quem-somos',
    robots: 'index, follow',
    twitterCard: 'summary',
    type: 'website',
    articleModifiedTime: '2024-05-05',
  },
  guaranteedPurchase: {
    title: 'Compra Garantida: Tranquilidade e Flexibilidade para Você | Saúde Trevo',
    description: 'Na Saúde Trevo, sua compra é 100% garantida. Cancele a qualquer momento antes de realizar o exame e receba seu dinheiro de volta, sem complicações. Nossa promessa de Compra Garantida assegura segurança, transparência e total tranquilidade na sua jornada de cuidado com a saúde.',
    image: 'https://web-cdn.saudetrevo.com.br/images/banner_quem_somos.webp',
    canonical: 'https://saudetrevo.com.br/compra-garantida',
    robots: 'index, follow',
    twitterCard: 'summary',
    type: 'website',
    articleModifiedTime: '2024-05-05',
  },
  faq: {
    title: 'FAQ: Tire Suas Dúvidas Sobre Exames e Agendamentos | Saúde Trevo',
    description: 'Encontre respostas claras para suas dúvidas sobre exames, agendamentos, pagamentos, cancelamentos e mais na Saúde Trevo. Nossa FAQ cobre tudo o que você precisa saber para realizar seus exames com segurança, transparência e tranquilidade.',
    image: 'https://web-cdn.saudetrevo.com.br/images/banner_quem_somos.webp',
    canonical: 'https://saudetrevo.com.br/faq',
    robots: 'index, follow',
    twitterCard: 'summary',
    type: 'website',
    articleModifiedTime: '2024-05-05',
  },
  protectedPayment: {
    title: 'Pagamento Protegido: Segurança e Confiança para Seus Exames | Saúde Trevo',
    description: 'Com o Pagamento Protegido da Saúde Trevo, sua compra é 100% segura. O pagamento só é processado após a confirmação do agendamento do seu exame pelo laboratório. Garantimos tranquilidade, segurança e suporte em cada etapa do processo. Cuide da sua saúde com confiança e sem surpresas!',
    image: 'https://web-cdn.saudetrevo.com.br/images/banner_quem_somos.webp',
    canonical: 'https://saudetrevo.com.br/pagamento-protegido',
    robots: 'index, follow',
    twitterCard: 'summary',
    type: 'website',
    articleModifiedTime: '2024-05-05',
  },
  PrivacyPolicy: {
    title: 'Políticas de Privacidade: Proteção e Transparência de Dados | Saúde Trevo',
    description: 'Saiba como a Saúde Trevo protege seus dados pessoais e sensíveis. Entenda nossa política de privacidade, coleta, armazenamento e uso de informações, conforme a LGPD. Transparência e segurança em cada interação com nossa plataforma.',
    image: 'https://web-cdn.saudetrevo.com.br/images/banner_quem_somos.webp',
    canonical: 'https://saudetrevo.com.br/politica-de-privacidade',
    robots: 'index, follow',
    twitterCard: 'summary',
    type: 'website',
    articleModifiedTime: '2024-05-05',
  },
  TermsAndConditions: {
    title: 'Termos e Condições: Transparência e Segurança nos Serviços | Saúde Trevo',
    description: 'Leia os Termos e Condições da Saúde Trevo. Entenda como funciona a intermediação entre usuários e laboratórios parceiros, direitos, formas de pagamento, cancelamentos, políticas de privacidade e tratamento de dados pessoais. Transparência e segurança em cada etapa do processo.',
    image: 'https://web-cdn.saudetrevo.com.br/images/banner_quem_somos.webp',
    canonical: 'https://saudetrevo.com.br/termos-e-condicoes',
    robots: 'index, follow',
    twitterCard: 'summary',
    type: 'website',
    articleModifiedTime: '2024-05-05',
  },
  widget: {
    robots: 'noindex, nofollow',
  },
  search: {
    title: 'Buscador de Exames | Saúde Trevo',
    description: 'Encontre exames médicos com economia, qualidade e transparência na Saúde Trevo! Compare preços em laboratórios parceiros e agende seus exames com facilidade. Cuidar da sua saúde nunca foi tão fácil!',
    image: 'https://web-cdn.saudetrevo.com.br/images/banner_quem_somos.webp',
    canonical: 'https://saudetrevo.com.br/buscar-exames',
    robots: 'index, follow',
    twitterCard: 'summary',
    type: 'website',
    articleModifiedTime: '2024-05-05',
  },
  schedule: {
    title: 'Agendamento de Exames | Saúde Trevo',
    description: 'Agende seus exames médicos com economia, qualidade e transparência na Saúde Trevo! Compare preços em laboratórios parceiros e encontre a melhor opção para você. Cuidar da sua saúde nunca foi tão fácil!',
    image: 'https://web-cdn.saudetrevo.com.br/images/banner_quem_somos.webp',
    canonical: 'https://saudetrevo.com.br/agendamento',
    twitterCard: 'summary',
    robots: 'noindex, nofollow',
    type: 'website',
    articleModifiedTime: '2024-05-05',
  },
  profile: {
    title: 'Área do Usuário | Saúde Trevo',
    description: 'Acesse sua Área do Usuário na Saúde Trevo e gerencie seus dados, exames, cupons e pedidos com facilidade. Cuidar da sua saúde nunca foi tão simples!',
    canonical: 'https://saudetrevo.com.br/perfil',
    robots: 'noindex, nofollow',
  },
  myCoupons: {
    title: 'Meus Cupons | Saúde Trevo',
    description: 'Acesse seus cupons na Saúde Trevo e aproveite descontos exclusivos em exames médicos. Economize com qualidade e transparência em sua jornada de cuidado com a saúde!',
    canonical: 'https://saudetrevo.com.br/perfil/meus-cupons',
    robots: 'noindex, nofollow',
  },
  myOrders: {
    title: 'Status do Pedido | Saúde Trevo',
    description: 'Acompanhe o status do seu pedido na Saúde Trevo e saiba em que etapa está o agendamento dos seus exames. Cuidar da sua saúde com economia e transparência nunca foi tão fácil!',
    canonical: 'https://saudetrevo.com.br/perfil/meus-pedidos',
    robots: 'noindex, nofollow',
  },
  myOrderStatus: {
    title: 'Status do Pedido | Saúde Trevo',
    description: 'Acompanhe o status do seu pedido na Saúde Trevo e saiba em que etapa está o agendamento dos seus exames. Cuidar da sua saúde com economia e transparência nunca foi tão fácil!',
    canonical: 'https://saudetrevo.com.br/perfil/meus-pedidos',
    robots: 'noindex, nofollow',
  },
  profilePersonalData: {
    title: 'Dados Cadastrais | Saúde Trevo',
    description: 'Gerencie seus dados cadastrais na Saúde Trevo e mantenha suas informações atualizadas. Cuidar da sua saúde com economia e transparência nunca foi tão fácil!',
    canonical: 'https://saudetrevo.com.br/perfil/dados-cadastrais',
    robots: 'noindex, nofollow',
  },
}
