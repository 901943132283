/**
 * Converts the keys of an object or array from `snake_case` to `camelCase`.
 *
 * - Works recursively on nested objects and arrays.
 * - Keeps the values unchanged, just converts the keys.
 *
 * @param {any} input - The input object or array. If it is not an object or array, it will be returned as is.
 * @returns {any} - The object or array with the keys converted to `camelCase`.
 *
 * @example
 * const input = { snake_case_key: { nested_key: 'value' } };
 * const result = snakeToCamel(input);
 * console.log(result); // { snakeCaseKey: { nestedKey: 'value' } }
 */
export function snakeToCamel(input: any): any {
  if (typeof input !== 'object' || input === null)
    return input

  if (Array.isArray(input))
    return input.map(item => snakeToCamel(item))

  const camelObject: { [key: string]: any } = {}

  for (const key in input) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      const camelKey = key.replace(/_(\w)/g, (_, p1) => p1.toUpperCase())
      camelObject[camelKey] = snakeToCamel(input[key])
    }
  }

  return camelObject
}

/**
 * Converts the keys of an object or array from `camelCase` to `snake_case`.
 *
 * - Works recursively on nested objects and arrays.
 * - Keeps the values unchanged, just converts the keys.
 *
 * @param {any} input - The input object or array. If it is not an object or array, it will be returned as is.
 * @returns {any} - The object or array with the keys converted to `snake_case`.
 *
 * @example
 * const input = { camelCaseKey: { nestedKey: 'value' } };
 * const result = camelToSnake(input);
 * console.log(result); // { camel_case_key: { nested_key: 'value' } }
 */
export function camelToSnake(input: any): any {
  if (typeof input !== 'object' || input === null)
    return input

  if (Array.isArray(input))
    return input.map(item => camelToSnake(item))

  const snakeObject: { [key: string]: any } = {}

  for (const key in input) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      const snakeKey = key.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`)
      snakeObject[snakeKey] = camelToSnake(input[key])
    }
  }

  return snakeObject
}
