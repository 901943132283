import type { ICustomer } from '@/types/types'
import { EventName, LocalStorageKey } from '@/types/enums'
import { jwtDecode } from 'jwt-decode'
import useCustomer from './useCustomer'

function useAuth() {
  const loading = useState('AuthLoading', () => false)

  const showSignInModal = useState('ShowSignInModal', () => false)
  const showSignUpModal = useState('ShowSignUpModal', () => false)
  const showTokenModal = useState('ShowTokenModal', () => false)
  const signInModalWithoutGoogle = useState('SignInModalWithoutGoogle', () => false)

  const errors = useState<Record<string, string | undefined>>('AuthErrors', () => ({}))

  return {
    showSignInModal,
    showSignUpModal,
    showTokenModal,
    signInModalWithoutGoogle,
    errors,
    loading,
    logout() {
      useLocalStorage(LocalStorageKey.AccessToken, '').value = null
      useLocalStorage(LocalStorageKey.RefreshToken, '').value = null

      const { customer } = useCustomer()

      customer.value = {} as ICustomer

      showSignUpModal.value = false

      if (useRoute().name?.toString().includes('checkout'))
        return navigateTo('/checkout')

      return navigateTo('/')
    },
  }
}

export default useAuth

export function useAuthGlobally() {
  const { signInModalWithoutGoogle, showSignInModal } = useAuth()
  const { customer } = useCustomer()

  const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')

  watch(accessToken, (newAccessToken) => {
    if (!newAccessToken) {
      return
    }

    const customerFromToken = snakeToCamel(jwtDecode(newAccessToken))

    setAdData(customerFromToken.phone)
    setAnalyticsData(customerFromToken.id)

    if (customer.value.picture) {
      delete customerFromToken.exp

      customer.value = {
        ...customerFromToken,
        name: customer.value.name,
        lastName: customer.value.lastName,
        email: customer.value.email,
      }
    }
    else {
      customer.value = snakeToCamel(jwtDecode(newAccessToken))

      useCustomerUpdate()
    }
  }, { immediate: true })

  watch(showSignInModal, (newShowSignInModal) => {
    if (newShowSignInModal) {
      track(EventName.OpenLoginModal)
    }
    else {
      signInModalWithoutGoogle.value = false
    }
  })
}
