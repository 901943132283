interface IToastOptions {
  severity: 'success' | 'info' | 'warn' | 'error' | 'secondary' | 'contrast'
  summary: string
  detail?: string
  life?: number
}
export default (options: IToastOptions) => {
  if (!options.severity || !options.summary || !options.detail) {
    return
  }

  const toast = useNuxtApp().vueApp.config.globalProperties.$toast

  toast.add({
    severity: options.severity,
    summary: options.summary,
    detail: options.detail,
    life: options.life || 8000,
  })
}
