import Service from '.'

class RecommendationsService extends Service {
  private RESOURCE = '/v1/recommendations'

  async getAddressByZipCode(zipCode: string = '') {
    const url = `${this.RESOURCE}/district/${zipCode}`

    const method = 'GET'

    const response = await this.call(method, url)

    if (response) {
      delete response.lng
      delete response.lat
    }

    return response
  }
}

export default RecommendationsService
