/**
 * Formats or removes formatting of a Brazilian phone number.
 *
 * @param {string | number} value - The string or number containing the phone number, which may or may not be formatted.
 * @returns {string}
 *   - Returns the cleaned phone number (digits only) if the input was formatted.
 *   - Returns the formatted phone number in the format "+55 (XX) 9 XXXX-XXXX" if the input was unformatted and valid (11 digits).
 *   - Otherwise, returns the original input unchanged.
 */
export default function formatPhone(value: string | number): string {
  const strValue = value?.toString()

  // eslint-disable-next-line regexp/strict
  if (/[^0-9()+-\s]/.test(strValue)) {
    return strValue
  }

  const hasFormatting = /[()+-]/.test(strValue)

  let cleanNumber = strValue.replace(/\D/g, '')

  if (hasFormatting) {
    return cleanNumber
  }
  else if (/^\d{11}$|^\d{13}$/.test(cleanNumber)) {
    if (cleanNumber.length === 13)
      cleanNumber = cleanNumber.slice(2)

    const countryCode = '55'
    const areaCode = cleanNumber.slice(0, 2)
    const firstDigit = cleanNumber.charAt(2)
    const firstPart = cleanNumber.slice(3, 7)
    const secondPart = cleanNumber.slice(7, 11)
    return `+${countryCode} (${areaCode}) ${firstDigit} ${firstPart}-${secondPart}`
  }
  else {
    return strValue
  }
}
