declare global {
  interface Window {
    hj: (command: string, userId: string, attributes?: Record<string, any>) => void
  }
}

export default (): void => {
  const deviceId = useTrackMixpanelDistinctId().value

  if (deviceId && typeof deviceId === 'string')
    window.hj('identify', deviceId, {})
}
