import type {
  AuthSendTokenErrorMessageType,
  CartErrorMessageType,
  CheckoutOrderErrorMessageType,
  ICustomer,
  IStepData,
  OrdenationOption,
  PhoneVerificationCodeErrorMessageType,
  PromoErrorMessageType,
  TCartErrorMessageType,
  TMedicalRequestErrorMessageType,
} from '@/types/types'

export enum PhoneVerificationCodeError {
  INVALID_CODE = 'E10005',
}

export enum LocationType {
  CITY = 'city',
  ZIP_CODE = 'zip_code',
  GEOLOCATION = 'geolocation',
}

export enum FeatureFlag {
  EnableCardAuth = 'enable_card_auth',
  EnableHealthPlan = 'enable_health_plan',
  MinValueToShowWppButton = 'min_value_to_show_wpp_button',
  BlockWithoutPrescription = 'block_without_prescription',
}

export enum OrderStatus {
  Pending = 'Pending',
  Denied = 'Denied',
  Canceled = 'Canceled',
  Approved = 'Approved',
  Ready = 'Ready',
  Finished = 'Finished',
  Refunded = 'Refunded',
  Expired = 'Expired',
  Pending_But_Authorized = 'Pending_But_Authorized',
  Waiting_For_Refund = 'Waiting_For_Refund',
  Waiting_For_Results = 'Waiting_For_Results',
  Schedule_Confirmation = 'Schedule_Confirmation',
}

export enum EventName {
  PageView = 'trevo_page_view',
  ExamInput = 'trevo_exam_input',
  ExamSelect = 'trevo_exam_select',
  ExamRemove = 'trevo_exam_remove',
  Search = 'trevo_search',
  OpenCloseDetails = 'trevo_open_close_details',
  SelectBundle = 'trevo_select_bundle',
  SelectOrdenation = 'trevo_select_ordenation',
  SelectBranch = 'trevo_select_branch',
  SelectSchedule = 'trevo_select_schedule',
  AddCart = 'trevo_add_cart',
  OpenLoginModal = 'trevo_open_login_modal',
  SendToken = 'trevo_send_token',
  ChangePhone = 'trevo_change_phone',
  TokenVerified = 'trevo_token_verified',
  UpdateUser = 'trevo_update_user',
  RedirectToBillingAddress = 'trevo_redirect_to_billing_address',
  RedirectToMedicalRequest = 'trevo_redirect_to_medical_request',
  RedirectToPayment = 'trevo_redirect_to_payment',
  RedirectToFinishWithHealthPlan = 'trevo_redirect_to_finish_with_health_plan',
  TokenCard = 'trevo_token_card',
  ErrorCreateTokenCard = 'trevo_error_create_token_card',
  FinishOrder = 'trevo_finish_order',
  PaymentOrder = 'trevo_payment_order',
  ErrorFinishOrder = 'trevo_error_finish_order',
  InitWppConversation = 'trevo_init_wpp_conversation',
  Error = 'trevo_error',
  OpenWppModal = 'trevo_open_wpp_modal',
  InitWppBundleCheckup = 'trevo_init_wpp_bundle_checkup',
  PaymentApproved = 'trevo_payment_approved',
  PaymentRejected = 'trevo_payment_rejected',
  CartExpired = 'trevo_cart_expired',
  ChangeLocation = 'trevo_change_location',
  OCRUpload = 'trevo_ocr_upload',
  ScheduleConfirmed = 'trevo_schedule_confirmed',
  ProcedureNotFound = 'trevo_procedure_not_found',
  CalendarClick = 'trevo_calendar_click',
  SelectHourClick = 'trevo_select_hour_click',
  ErrorCartPatch = 'trevo_cart_error_patch',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export enum SearchProcedureOrdenation {
  RELEVANCE = 'relevance',
  PRICE = 'price',
  DISTANCE = 'distance',
  CLOSEST_DAY = 'closest_day',
}

export const SearchProcedureOrdenationBase = {
  [SearchProcedureOrdenation.RELEVANCE]: { name: 'Maior relevância', value: SearchProcedureOrdenation.RELEVANCE } as OrdenationOption,
  [SearchProcedureOrdenation.DISTANCE]: { name: 'Menor distância', value: SearchProcedureOrdenation.DISTANCE } as OrdenationOption,
}

export const SearchProcedureOrdenationMapping = {
  ...SearchProcedureOrdenationBase,
  [SearchProcedureOrdenation.PRICE]: { name: 'Menor preço', value: SearchProcedureOrdenation.PRICE } as OrdenationOption,
}

export const SearchProcedureOrdenationMappingWithDate = {
  ...SearchProcedureOrdenationBase,
  [SearchProcedureOrdenation.CLOSEST_DAY]: { name: 'Data mais próxima', value: SearchProcedureOrdenation.CLOSEST_DAY } as OrdenationOption,
}

export enum ProfilePaymentType {
  Pix = 'Pix',
  CreditCard = 'Credit_Card',
  NotPayment = 'not-payment',
}

export enum AuthSendTokenError {
  INVALID_PHONE = 'E10004',
  CANNOT_REQUEST_VERIFICATION_CODE = 'E10009',
  BLOCKED_FROM_REQUESTING_CODE = 'E10010',
  UNPROCESSABLE_ENTITY = 'unprocessable_entity',
}

export const AuthSendTokenErrorMessage: AuthSendTokenErrorMessageType = {
  [AuthSendTokenError.INVALID_PHONE]: {
    message: 'Telefone inválido.',
    formFields: ['phone'],
  },
  [AuthSendTokenError.CANNOT_REQUEST_VERIFICATION_CODE]: {
    message: 'Código foi enviado recentemente e não pode ser enviado de novo.',
    formFields: ['phone'],
  },
  [AuthSendTokenError.BLOCKED_FROM_REQUESTING_CODE]: {
    message: 'Você foi bloqueado por muitas tentativas. Por favor, aguarde alguns minutos e tente novamente.',
    formFields: ['phone'],
  },
  [AuthSendTokenError.UNPROCESSABLE_ENTITY]: {
    message: 'Formato de dados incorreto. Por favor, verifique as informações e tente novamente.',
    formFields: ['phone'],
  },
}

export interface CustomerErrorInfo {
  message: string
  formFields: (keyof ICustomer)[]
}

export type CustomerErrorMessageType = {
  [key in CustomerError]: CustomerErrorInfo
}

export enum CustomerError {
  INVALID_CPF = 'E10003',
  TAX_ID_ALREADY_EXISTS = 'E10025',
  EMAIL_ALREADY_EXISTS = 'E10026',
  PHONE_ALREADY_EXISTS = 'E10027',
  USER_NOT_FOUND = 'not_found',
  UNPROCESSABLE_ENTITY = 'unprocessable_entity',
}

export const CustomerErrorMessage: CustomerErrorMessageType = {
  [CustomerError.INVALID_CPF]: {
    message: 'CPF do usuário inválido.',
    formFields: ['taxId'],
  },
  [CustomerError.TAX_ID_ALREADY_EXISTS]: {
    message: 'Já existe um usuário cadastrado com este CPF.',
    formFields: ['taxId'],
  },
  [CustomerError.EMAIL_ALREADY_EXISTS]: {
    message: 'Já existe um usuário cadastrado com este e-mail.',
    formFields: ['email'],
  },
  [CustomerError.PHONE_ALREADY_EXISTS]: {
    message: 'Telefone já existente.',
    formFields: ['phone'],
  },
  [CustomerError.USER_NOT_FOUND]: {
    message: 'Usuário não encontrado.',
    formFields: [],
  },
  [CustomerError.UNPROCESSABLE_ENTITY]: {
    message: 'Erro ao cadastrar.',
    formFields: [],
  },
}

export const PhoneVerificationCodeErrorMessage: PhoneVerificationCodeErrorMessageType = {
  [PhoneVerificationCodeError.INVALID_CODE]: {
    message: 'Código de verificação inválido.',
  },
}

export enum LocalStorageKey {
  Cart = 'Cart',
  AccessToken = 'AccessToken',
  RefreshToken = 'RefreshToken',
  TEST_AB = 'testAB',
  PRESCRIPTION_HASHES = 'prescriptionHashes',
  GCLID = 'gclid',
  GBRAID = 'gbraid',
  WBRAID = 'wbraid',
}

export enum SessionStorageKey {
  Precriptions = 'prescriptions',
  OrderCreated = 'order_created',
  UserFromCartShared = 'UserFromCartShared',
  LocationParam = 'locationParam',
  SuggestedBundles = 'suggestedBundles',
  BundleSelected = 'bundleSelected',
  BranchesSelected = 'branchesSelected',
  OrderToPay = 'orderToPay',
  ProceduresSearched = 'proceduresSearched',
  GeolocationPopup = 'geolocationPopup',
  GeolocationPopupClosedInSession = 'geolocationPopupCloseInSession',
  SelectedHealthPlan = 'selectedHealthPlan',
}

export enum TestABGroups {
  NEW_JOURNEY_WITH_SCHEDULE_DATE = 'testNewJourneyWithScheduleDate',
  NEW_JOURNEY_WITH_SCHEDULE_DATE_CONTROL = 'testNewJourneyWithScheduleDateControl',
  RESET_AB_TEST = 'resetABTest',
  IGNORE_AB_TEST = 'ignoreABTest',
}

export enum UserIdentifiersEvent {
  CHAT_INTERACTION = 'chat_interaction',
  LOGIN = 'login',
  CHECKOUT = 'checkout',
}

export enum CHECKOUT_STEPS {
  BUDGET = 'BUDGET',
  PERSONAL_DATA = 'PERSONAL_DATA',
  BILLING_ADDRESS = 'BILLING_ADDRESS',
  MEDICAL_REQUEST = 'MEDICAL_REQUEST',
  PAYMENT = 'PAYMENT',
  FINISH = 'FINISH',
  REALIZED = 'REALIZED',
}

export enum CheckoutOrderError {
  ORDER_NOT_FOUND = 'E13007',
  ORDER_NOT_ELIGIBLE = 'E13051',
  INSTALLMENT_NUMBER_HIGHER = 'E13018',
  CARD_TOKEN_REQUIRED = 'E13030',
  PAYMENT_GATEWAY = 'E13037',
}

export enum MedicalRequestError {
  CART_NOT_FOUND = 'E21005',
}

export const CheckoutOrderErrorMessage: CheckoutOrderErrorMessageType = {
  [CheckoutOrderError.ORDER_NOT_FOUND]: 'Pedido não encontrado',
  [CheckoutOrderError.ORDER_NOT_ELIGIBLE]: 'Pedido não elegível para checkout',
  [CheckoutOrderError.INSTALLMENT_NUMBER_HIGHER]: 'Número de parcelas maior que o permitido',
  [CheckoutOrderError.CARD_TOKEN_REQUIRED]: 'Token do cartão é necessário para pagamento com cartão',
  [CheckoutOrderError.PAYMENT_GATEWAY]: 'Erro inesperado no gateway de pagamentos',
}

export const MedicalRequestErrorMessage: TMedicalRequestErrorMessageType = {
  [MedicalRequestError.CART_NOT_FOUND]: {
    message: 'Orçamento não encontrado, tente novamente mais tarde.',
    toast: true,
    toastVariant: 'warn',
  },
}

export enum Category {
  ClinicalAnalyzes = 'analises-clinicas',
  XRay = 'raio-x',
  Ultrasound = 'ultrassom',
  Tomography = 'tomografia',
  MagneticResonance = 'ressonancia-magnetica',
  Others = 'outros',
}

export enum Cookie {
  TestAb = 'testAB',
  LocationParam = 'locationParam',
}

export enum CartError {
  PROMO_NOT_FOUND = 'E17001',
  PROMO_MIN_VALUE_NOT_REACHED = 'E17007',
  PROMO_CANNOT_USE_COUPON = 'E17005',
  PROMO_INVALID = 'E16011',

  NOT_FOUND_ADDRESS = 'E27001',
  MISSING_CART_ADDRESS_FIELDS = 'E21030',

  CART_NOT_FOUND = 'E21005',
  WITHOUT_PROCEDURES = 'E21007',
  WITHOUT_ADDRESS = 'E21006',
  PROMO_MINIMUM_PRICE_NOT_REACHED = 'E21021',
  CART_IS_NOT_ACTIVE = 'E21009',
  PROCEDURE_IS_NOT_ACTIVE = 'E21014',
  FAILED_TO_APPLY_COUPON = 'E21011',
  WITHOUT_USER_NAME = 'E21012',
  FINISH_SALE = 'E21008',
}

export const CheckoutError: TCartErrorMessageType = {
  [CartError.CART_NOT_FOUND]: {
    message: 'Orçamento não encontrado.',
    toast: true,
    toastVariant: 'error',
  },

  [CartError.MISSING_CART_ADDRESS_FIELDS]: {
    message: 'Alguns dados estão faltando no endereço de cobrança.',
    toast: true,
    toastVariant: 'warn',
  },

  [CartError.WITHOUT_PROCEDURES]: {
    message: 'Orçamento sem exames.',
    toast: true,
    toastVariant: 'error',
  },

  [CartError.WITHOUT_ADDRESS]: {
    message: 'Orçamento sem endereço.',
    toast: true,
    toastVariant: 'error',
  },

  [CartError.PROMO_MINIMUM_PRICE_NOT_REACHED]: {
    message: 'Valor mínimo para uso do cupom não atingido.',
    toast: true,
    toastVariant: 'error',
  },

  [CartError.CART_IS_NOT_ACTIVE]: {
    message: 'Orçamento desativado.',
    toast: true,
    toastVariant: 'error',
  },

  [CartError.PROCEDURE_IS_NOT_ACTIVE]: {
    message: 'Há procedimentos desativados em seu orçamento.',
    toast: true,
    toastVariant: 'error',
  },

  [CartError.WITHOUT_USER_NAME]: {
    message: 'Usuário sem nome cadastrado.',
    toast: true,
    toastVariant: 'error',
  },

  [CartError.FAILED_TO_APPLY_COUPON]: {
    message: 'Falha ao aplicar cupom, tente novamente.',
    toast: true,
    toastVariant: 'error',
  },

  [CartError.FINISH_SALE]: {
    message: 'Falha ao finalizar venda, tente novamente.',
    toast: true,
    toastVariant: 'error',
  },
}

export const CartErrorMessage: CartErrorMessageType = {
  [CartError.PROMO_NOT_FOUND]: 'Cupom não encontrado.',
  [CartError.PROMO_MIN_VALUE_NOT_REACHED]: 'Valor mínimo para uso do cupom não atingido.',
  [CartError.PROMO_CANNOT_USE_COUPON]: 'Este cupom não é válido para esta compra.',
  [CartError.PROMO_INVALID]: 'Cupom inválido.',

  [CartError.NOT_FOUND_ADDRESS]: 'Falha ao salvar endereço de cobrança. Verifique os dados e tente novamente.',

  [CartError.CART_NOT_FOUND]: 'Orçamento não encontrado.',
  [CartError.WITHOUT_PROCEDURES]: 'Orçamento sem exames.',
  [CartError.WITHOUT_ADDRESS]: 'Orçamento sem endereço.',
  [CartError.PROMO_MINIMUM_PRICE_NOT_REACHED]: 'Valor mínimo para uso do cupom não atingido.',
  [CartError.CART_IS_NOT_ACTIVE]: 'Orçamento desativado.',
  [CartError.PROCEDURE_IS_NOT_ACTIVE]: 'Há procedimentos desativados em seu orçamento.',
  [CartError.WITHOUT_USER_NAME]: 'Usuário sem nome cadastrado.',
  [CartError.FAILED_TO_APPLY_COUPON]: 'Falha ao aplicar cupom, tente novamente.',
  [CartError.FINISH_SALE]: 'Falha ao finalizar venda, tente novamente.',
}

export enum PromoError {
  NOT_FOUND = 'E17001',
  MIN_VALUE_NOT_REACHED = 'E17007',
  CANNOT_USE_COUPON = 'E17005',
  PROMO_INVALID = 'E16011',
}

export const PromoErrorMessage: PromoErrorMessageType = {
  [PromoError.NOT_FOUND]: {
    message: 'Cupom não encontrado.',
    formFields: ['promoSearch'],
  },
  [PromoError.MIN_VALUE_NOT_REACHED]: {
    message: 'Valor mínimo para uso do cupom não atingido.',
    formFields: ['promoSearch'],
  },
  [PromoError.CANNOT_USE_COUPON]: {
    message: 'Este cupom não é válido para esta compra.',
    formFields: ['promoSearch'],
  },
  [PromoError.PROMO_INVALID]: {
    message: 'Cupom inválido.',
    formFields: ['promoSearch'],
  },
}

export const CHECKOUT_STEP_DATA = {
  [CHECKOUT_STEPS.BUDGET]: {
    title: 'Resumo do pedido',
    percent: 14,
  } as IStepData,
  [CHECKOUT_STEPS.PERSONAL_DATA]: {
    title: 'Dados pessoais',
    percent: 28,
  } as IStepData,
  [CHECKOUT_STEPS.BILLING_ADDRESS]: {
    title: 'Endereço de cobrança',
    percent: 57,
  } as IStepData,
  [CHECKOUT_STEPS.MEDICAL_REQUEST]: {
    title: 'Pedido Médico',
    percent: 71,
  } as IStepData,
  [CHECKOUT_STEPS.PAYMENT]: {
    title: 'Forma de Pagamento',
    percent: 85,
  } as IStepData,
  [CHECKOUT_STEPS.REALIZED]: {
    title: 'Pedido Realizado',
    percent: 100,
  } as IStepData,
}

export enum Error {
  // cart / promo
  PROMO_NOT_FOUND = 'E17001',
  PROMO_MIN_VALUE_NOT_REACHED = 'E17007',
  PROMO_CANNOT_USE_COUPON = 'E17005',
  PROMO_INVALID = 'E16011',

  // cart
  NOT_FOUND_ADDRESS = 'E27001',
  MISSING_CART_ADDRESS_FIELDS = 'E21030',

  CART_NOT_FOUND = 'E21005',
  WITHOUT_PROCEDURES = 'E21007',
  WITHOUT_ADDRESS = 'E21006',
  PROMO_MINIMUM_PRICE_NOT_REACHED = 'E21021',
  CART_IS_NOT_ACTIVE = 'E21009',
  PROCEDURE_IS_NOT_ACTIVE = 'E21014',
  FAILED_TO_APPLY_COUPON = 'E21011',
  WITHOUT_USER_NAME = 'E21012',
  FINISH_SALE = 'E21008',

  // checkout
  ORDER_NOT_FOUND = 'E13007',
  ORDER_NOT_ELIGIBLE = 'E13051',
  INSTALLMENT_NUMBER_HIGHER = 'E13018',
  CARD_TOKEN_REQUIRED = 'E13030',
  PAYMENT_GATEWAY = 'E13037',

  // customer
  INVALID_CPF = 'E10003',
  TAX_ID_ALREADY_EXISTS = 'E10025',
  EMAIL_ALREADY_EXISTS = 'E10026',
  PHONE_ALREADY_EXISTS = 'E10027',
  USER_NOT_FOUND = 'not_found',
  UNPROCESSABLE_ENTITY = 'unprocessable_entity',

  // auth
  INVALID_PHONE = 'E10004',
  CANNOT_REQUEST_VERIFICATION_CODE = 'E10009',
  BLOCKED_FROM_REQUESTING_CODE = 'E10010',
}

export const ErrorMessage = {
  [Error.PROMO_NOT_FOUND]: 'Cupom não encontrado.',
  [Error.PROMO_MIN_VALUE_NOT_REACHED]: 'Valor mínimo para uso do cupom não atingido.',
  [Error.PROMO_CANNOT_USE_COUPON]: 'Este cupom não é válido para esta compra.',
  [Error.PROMO_INVALID]: 'Cupom inválido.',

  [Error.NOT_FOUND_ADDRESS]: 'Falha ao salvar endereço de cobrança. Verifique os dados e tente novamente.',

  [Error.CART_NOT_FOUND]: 'Orçamento não encontrado.',
  [Error.WITHOUT_PROCEDURES]: 'Orçamento sem exames.',
  [Error.WITHOUT_ADDRESS]: 'Orçamento sem endereço.',
  [Error.PROMO_MINIMUM_PRICE_NOT_REACHED]: 'Valor mínimo para uso do cupom não atingido.',
  [Error.CART_IS_NOT_ACTIVE]: 'Orçamento desativado.',
  [Error.PROCEDURE_IS_NOT_ACTIVE]: 'Há procedimentos desativados em seu orçamento.',
  [Error.WITHOUT_USER_NAME]: 'Usuário sem nome cadastrado.',
  [Error.FAILED_TO_APPLY_COUPON]: 'Falha ao aplicar cupom, tente novamente.',
  [Error.FINISH_SALE]: 'Falha ao finalizar venda, tente novamente.',

  [Error.BLOCKED_FROM_REQUESTING_CODE]: 'Você foi bloqueado por muitas tentativas. Por favor, aguarde alguns minutos e tente novamente.',

  [CheckoutOrderError.ORDER_NOT_FOUND]: 'Pedido não encontrado',
  [CheckoutOrderError.ORDER_NOT_ELIGIBLE]: 'Pedido não elegível para checkout',
  [CheckoutOrderError.INSTALLMENT_NUMBER_HIGHER]: 'Número de parcelas maior que o permitido',
  [CheckoutOrderError.CARD_TOKEN_REQUIRED]: 'Token do cartão é necessário para pagamento com cartão',
  [CheckoutOrderError.PAYMENT_GATEWAY]: 'Erro inesperado no gateway de pagamentos',
}
