import type { IAddress, ICustomer } from '@/types/types'

import { CustomerErrorMessage, EventName, LocalStorageKey } from '@/types/enums'

import useAuth from './useAuth'

const genderOptions = [
  {
    text: 'Feminino',
    value: 'feminino',
  },
  {
    text: 'Feminino transgênero',
    value: 'feminino_trans',
  },
  {
    text: 'Masculino',
    value: 'masculino',
  },
  {
    text: 'Masculino transgênero',
    value: 'masculino_trans',
  },
  {
    text: 'Não binário',
    value: 'nao_binario',
  },
  {
    text: 'Outro',
    value: 'outro',
  },
]

export async function useCustomerUpdateAddress(address: Ref<IAddress>) {
  const { customer } = useCustomer()

  return await $fetch(`/v1/customer/users/${customer.value.id}/address`, {
    baseURL: useRuntimeConfig().public.api.awsGateway,
    method: 'PATCH',
    headers: {
      AuthToken: useLocalStorage(LocalStorageKey.AccessToken, '').value,
    },
    body: camelToSnake({ ...address.value, postalCode: address.value.postalCode?.replace(/-/g, ''), userId: customer.value.id }),
  })
}

export function useCustomer() {
  const customer = useState<ICustomer>('Customer', () => ({} as ICustomer))
  const loading = useState<boolean>('CustomerLoading', () => false)
  const errors = useState<Record<string, string | undefined>>('CustomerErrors', () => ({}))

  return {
    customer,
    loading,
    errors,
    genderOptions,
  }
}

export default useCustomer

export function useCustomerGlobally() {
  const { customer, loading, errors } = useCustomer()
  const { showSignUpModal, showTokenModal, signInModalWithoutGoogle } = useAuth()

  watch(customer, async (newCustomer, oldCustomer) => {
    if (newCustomer.picture) {
      if (!isNewJourney() && (useRoute().name !== 'checkout' || useRoute().name !== 'checkout-dados-pessoais'))
        signInModalWithoutGoogle.value = true

      return
    }

    if (!newCustomer.id)
      return

    if (newCustomer.exp)
      return

    if (
      !customer.value.name
      || !customer.value.email
      || !customer.value.birthDate
      || !customer.value.gender
    ) {
      showTokenModal.value = false

      if (isNewJourney() && ['checkout', 'checkout-dados-pessoais'].includes(useRoute().name as string)) {
        if (useRoute().name !== 'checkout-dados-pessoais') {
          useCustomerUpdate()
        }

        return navigateTo('/checkout/dados-pessoais')
      }
      else {
        showSignUpModal.value = true
      }

      return
    }

    if (!oldCustomer.id) {
      if (isNewJourney() && ['checkout', 'checkout-dados-pessoais'].includes(useRoute().name as string)) {
        if (useRoute().name !== 'checkout-dados-pessoais') {
          useCustomerUpdate()
        }

        return navigateTo('/checkout/dados-pessoais')
      }

      return
    }

    if (oldCustomer.exp) {
      showTokenModal.value = false

      if (isNewJourney() && ['checkout', 'checkout-dados-pessoais'].includes(useRoute().name as string)) {
        return navigateTo('/checkout/dados-pessoais')
      }

      return
    }

    try {
      loading.value = true
      errors.value = {}

      const baseURL = useRuntimeConfig().public.api.awsGateway

      const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string

      await $fetch(`/v1/customer/users/${customer.value.id}`, {
        baseURL,
        method: 'PATCH',
        body: JSON.stringify(camelToSnake(customer.value)),
        headers: {
          Authtoken: accessToken,
        },
      })

      track(EventName.UpdateUser)

      showSignUpModal.value = false
    }
    catch (error: any) {
      handleApiErrors(
        error,
        errors,
        CustomerErrorMessage,
        'Falha ao salvar dados do usuário. Verifique os dados e tente novamente.',
      )
    }
    finally {
      loading.value = false
    }
  }, { deep: true })
}
