import { Error } from '@/types/constants'

import { EventName } from '@/types/enums'
import { captureException } from '@sentry/vue'

export default (error: any): void => {
  const isMapped = error.response && error.response._data?.error_code && Object.keys(Error).includes(error.response._data?.error_code as string)

  if (!isMapped) {
    const payload = {
      errorCode: error?.data?.error_code,
      errorName: EventName.Error,
      errorMessage: error?.data?.error_message,
      errorCause: error?.data?.error_message,
    }

    track(EventName.Error, payload)
    captureException(payload)
  }

  toast({
    severity: isMapped ? 'warn' : 'error',
    summary: isMapped ? 'Aviso' : 'Erro',
    detail: isMapped ? Error[error?.response._data?.error_code as string] : 'Ocorreu um erro. Por favor, tente novamente.',
  })
}
