import type { EventName } from '@/types/enums'
import type { IBundleNewJourney } from '@/types/types'
import useCustomer from '@/modules/customer/composables/useCustomer'
import { abTestControl } from '@/types/constants'
import { Cookie, SessionStorageKey, TestABGroups } from '@/types/enums'
import * as Sentry from '@sentry/vue'
import mixpanel from 'mixpanel-browser'

interface IEventInfo {
  [key: string]: any
}
function track(name: EventName, info: IEventInfo = {} as IEventInfo): void {
  try {
    if (!useTrackInitialized().value) {
      initializeTracking().then(() => {
        track(name, info)
      })

      return
    }

    const selectedBundle = useSessionStorage<IBundleNewJourney>(SessionStorageKey.BundleSelected, {} as IBundleNewJourney)

    info.price = Object.keys(selectedBundle.value).length ? selectedBundle.value?.bundlePrice : useMinBundleValue().value
    info.userId = useCustomer().customer.value?.id
    info.page = useRoute().name as string

    const abTest = useCookie<TestABGroups>(Cookie.TestAb)
    const abTestInfo = abTestControl[abTest.value] || abTestControl[TestABGroups.RESET_AB_TEST]

    info.abTest = abTestInfo?.name
    info.abTestFullName = abTestInfo?.fullname
    info.abTestControl = abTestInfo?.control

    info.userAgent = navigator?.userAgent

    useTrackEvent(name, {
      deviceId: useTrackMixpanelDistinctId().value,
      ...info,
    })

    mixpanel.track(name, {
      ...info,
      sessionId: useTrackGoogleSessionId().value,
      clientId: useTrackGoogleClientId().value,
    })
  }
  catch (error) {
    console.error('Error tracking event:', error)
    Sentry.captureException(error)
  }
}

export default track
