import { useTrackGoogleClientId, useTrackGoogleSessionId } from '@/composables/track'
import * as Sentry from '@sentry/vue'
import mixpanel from 'mixpanel-browser'

import setHotjarData from '~/utils/tracking/setHotjarData'

enum GtagTarget {
  SessionId = 'session_id',
  ClientId = 'client_id',
}

function _gtag(target: GtagTarget, callback: any): Promise<void> {
  return new Promise((resolve, reject) => {
    const { gtag } = useGtag()
    const { gtagId } = useRuntimeConfig().public

    if (!gtag || !gtagId) {
      const error = new Error('gtag or gtagId is not defined.')

      Sentry.captureException(error)
      reject(error)

      return
    }

    gtag('get', gtagId, target, (result: any) => {
      callback(result)
      resolve()
    })
  })
}

export default async () => {
  return new Promise((resolve, reject) => {
    try {
      const { mixpanelKey } = useRuntimeConfig().public

      const gtagPromises = [
        !useTrackGoogleSessionId().value && _gtag(GtagTarget.SessionId, (sessionId: string) => useTrackGoogleSessionId().value = sessionId),
        !useTrackGoogleClientId().value && _gtag(GtagTarget.ClientId, (clientId: string) => useTrackGoogleClientId().value = clientId),
      ].filter(Boolean)

      const mixpanelPromise = new Promise<void>((resolve) => {
        mixpanel.init(mixpanelKey as string, {
          debug: false,
          track_pageview: true,
          persistence: 'localStorage',
          loaded: () => {
            useTrackMixpanelDistinctId().value = mixpanel.get_distinct_id().split(':')[1]
            resolve()
          },
        })
      })

      Promise.all([...gtagPromises, mixpanelPromise])
        .then(() => {
          useTrackInitialized().value = true

          setHotjarData()

          resolve(true)
        })
        .catch((error) => {
          console.error('Error initializing tracking:', error)
          Sentry.captureException(error)
          reject(error)
        })
    }
    catch (error) {
      console.error('Error initializing tracking:', error)
      Sentry.captureException(error)
      reject(error)
    }
  })
}
