import type { ISessionTokens } from '@/types/types'
import { jwtDecode } from 'jwt-decode'
import useAuth from './useAuth'

export default async () => {
  const { refreshToken, accessToken } = useAuth()

  if (!refreshToken.value)
    return

  const refreshTokenValue = snakeToCamel(jwtDecode(refreshToken.value))
  if (refreshTokenValue.exp * 1000 > Date.now())
    return

  const baseURL = useRuntimeConfig().public.api.awsGateway

  let response = await $fetch<ISessionTokens>('/v1/customer/users/refresh_token', {
    method: 'POST',
    baseURL,
    body: JSON.stringify(camelToSnake({ refreshToken: refreshToken.value })),
  })

  response = snakeToCamel(response)

  accessToken.value = response.accessToken
  refreshToken.value = response.refreshToken
}
