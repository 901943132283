import type { ICustomer } from '@/types/types'
import { LocalStorageKey } from '@/types/enums'
import useAuth from './useAuth'
import useAuthRefreshToken from './useAuthRefreshToken'
import useCustomer from './useCustomer'

export default async () => {
  const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string
  const refreshToken = localStorage.getItem(LocalStorageKey.RefreshToken) as string

  const { logout } = useAuth()
  const { loading, customer } = useCustomer()

  try {
    loading.value = true

    const baseURL = useRuntimeConfig().public.api.awsGateway
    const response = await $fetch<ICustomer>(`/v1/customer/users/${customer.value.id}`, {
      baseURL,
      headers: {
        AuthToken: accessToken,
      },
    })

    customer.value = snakeToCamel(response)
  }
  catch (error: any) {
    if (error.response.status === 403) {
      if (refreshToken) {
        useAuthRefreshToken()

        return
      }

      toast({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Sessão expirada, faça login novamente.',
      })

      logout()

      return
    }

    errorTracker(error)
  }
  finally {
    loading.value = false
  }
}
