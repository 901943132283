import type { IErrorInfo } from '@/types/types'
import type { Ref } from 'vue'
import { useCustomer } from '@/modules/customer/composables/useCustomer'
import { EventName } from '@/types/enums'
import { captureException } from '@sentry/vue'

interface ErrorMapping {
  [errorCode: string]: IErrorInfo
}

export default (
  error: any,
  errorsRef: Ref<Record<string, string | undefined>> = ref<Record<string, string | undefined>>({}),
  errorMapping: ErrorMapping,
  defaultErrorMessage: string = 'Ocorreu um erro. Por favor, tente novamente.',
) => {
  errorsRef.value = {}

  if (error.response && error.response._data?.error_code && errorMapping[error.response._data?.error_code]) {
    const errorInfo = errorMapping[error.response._data?.error_code]

    if (errorInfo.toast) {
      return toast({
        severity: errorInfo.toastVariant || 'error',
        summary: errorInfo.toastVariant === 'error' || !errorInfo.toastVariant ? 'Erro' : 'Atenção',
        detail: errorInfo.message,
      })
    }

    return errorInfo.formFields?.forEach((field) => {
      errorsRef.value[field] = errorInfo.message
      errorsRef.value.form = errorInfo.message
    })
  }

  errorsRef.value.form = defaultErrorMessage

  const errorPayload = {
    errorCode: error?.data?.error_code,
    errorName: EventName.Error,
    errorMessage: error?.data?.error_message,
    errorCause: error?.data?.error_message,
    stackTrace: error?.stack,
    requestUrl: error?.request,
    responseStatus: error?.statusCode || error?.status,
    requestMethod: error?.options?.method,
    userId: useCustomer().customer.value?.id,
    errorMessageToUser: defaultErrorMessage,
  }

  track(EventName.Error, errorPayload)
  captureException(errorPayload)
}
