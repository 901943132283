import { SessionStorageKey } from '@/types/enums'

export default () => {
  const procedures = useSessionStorage(SessionStorageKey.ProceduresSearched, [] as any[])

  if (!procedures.value.length)
    return

  const slugs = procedures.value.map(procedure => procedure.slug)

  if (slugs.length === 1)
    return navigateToWithLocation(`/exames/${slugs[0]}`)

  return navigateToWithLocation(`/exames?exames=${slugs?.join(',')}`)
}
