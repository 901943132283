interface IGtagData {
  phoneNumber?: string
}
export default (phone: string | number): void => {
  if (!phone)
    return

  const userGtagData: IGtagData = {
    phoneNumber: formatPhone(phone),
  }

  const { gtag } = useGtag()
  gtag('set', 'user_data', userGtagData)
  gtag('event', 'form_submit', { send_to: useRuntimeConfig().public.gtagAdsId })
}
