import type { FetchOptions } from 'ofetch'
import Customer from '@/services/customer'

import Order from '@/services/order'
import Recommendations from '@/services/recommendations'
import { $fetch } from 'ofetch'

interface IApiInstance {
  recommendations: Recommendations
  customer: Customer
  order: Order
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const trevoOptions: FetchOptions = {
    baseURL: config.public.api.awsGateway,
  }

  const trevoFetcher = $fetch.create(trevoOptions)

  const api: IApiInstance = {
    recommendations: new Recommendations(trevoFetcher),
    customer: new Customer(trevoFetcher),
    order: new Order(trevoFetcher),
  }

  return {
    provide: {
      api,
    },
  }
})
