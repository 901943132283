import { LocalStorageKey } from '@/types/enums'
import Service from '.'

class OrderService extends Service {
  private RESOURCE = '/v1/sales-order'

  async getCancellationReasons() {
    const url = `${this.RESOURCE}/cancellation-reasons?order_by=requires_explanation`

    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string

    const method = 'GET'

    const headers = {
      Authtoken: accessToken,
    }

    return await this.call(method, url, undefined, headers)
  }

  async cancelOrder(orderId: string, payload: any) {
    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string
    const { customer } = useCustomer()

    const url = `/v1/customer/users/${customer.value.id}/orders/${orderId}/refund`

    const method = 'POST'

    const headers = {
      Authtoken: accessToken,
    }

    return await this.call(method, url, payload, headers)
  }
}

export default OrderService
