import type {
  IAddress,
  IGoogleUserInfo,
  ISessionTokens,
  IUser,
} from '@/types/types'
import { LocalStorageKey } from '@/types/enums'

import Service from '.'

class CustomerService extends Service {
  private RESOURCE = '/v1/customer/users'

  async saveUser(id: string = '', body: IUser) {
    const url = `${this.RESOURCE}/${id}`

    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string

    const method = 'PATCH'

    const header = {
      Authtoken: accessToken,
    }

    const response = await this.call(method, url, body, header)

    return response
  }

  async getUser(id: string = '') {
    const url = `${this.RESOURCE}/${id}`

    const method = 'GET'

    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string

    const header = {
      Authtoken: accessToken,
    }

    return await this.call(method, url, undefined, header)
  }

  async getAddress(userId: string): Promise<IAddress> {
    const url = `${this.RESOURCE}/${userId}/address`

    const method = 'GET'

    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string

    const header = {
      Authtoken: accessToken,
    }

    return await this.call(method, url, undefined, header)
  }

  async updateAddress(userID: string = '', address: any) {
    const url = `${this.RESOURCE}/${userID}/address`

    const method = 'PATCH'

    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string

    const header = {
      Authtoken: accessToken,
    }

    const { status } = await useAsyncData(() =>
      this.call(method, url, address, header),
    )

    if (status.value !== 'success')
      throw new Error('Error: update address')
  }

  async updateOrderStatus(userId: string, orderId: string) {
    const url = `${this.RESOURCE}/${userId}/orders/${orderId}`

    const method = 'GET'

    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string

    const headers = {
      Authtoken: accessToken,
    }

    return await this.call(method, url, undefined, headers)
  }

  authGoogle(credential = '') {
    const url = `${this.RESOURCE}/google/auth`

    const method = 'POST'

    const body = { credential }

    return useAsyncData<ISessionTokens | IGoogleUserInfo>(() =>
      this.call(method, url, body),
    )
  }
}

export default CustomerService
